<template>
    <div id="main-container" class="main-container">
        <div id="title-container">
            <h1 class="typo-headline-6">Calculadora de Interés compuesto</h1>
            <p class="typo-body-2">Herramienta para calcular el importe total obtenido al pasar el tiempo establecido de tu inversión</p>
        </div>
        <div v-if="this.show_popup" id="popup-container" class="popup-style-container">
            <div id="popup-modal" class="popup-modal">
                <div class="logo-eurekers">
                    <img src="../assets/img/eurekers.jpg" title="logo-eurekers" width=50 height=50 />
                </div>
                <div id="popup-form-container" class="popup-form-container">
                    <div id="text-container" class="text-container">
                        <p>
                            En Eurekers tenemos herramientas como esta, que te ayudarán a gestionar tus inversiones. Si quieres, déjanos tu teléfono e email para que te podamos enviar más información.
                        </p>
                    </div>
                    <div id="popup_tlf-container" class="popup_tlf-container">
                        <span>
                            <b>Teléfono</b>
                        </span>
                        <div>
                            <InputTextOutline @_emit_method_order_input="_getUserTlf" :input_label="input_popup[0].input_label" :has_focus="input_popup[0].has_focus" :icon_class="input_popup[0].icon_class" :input_key_to_save_user_values="input_popup[0].input_key_to_save_user_values" :input_class_character_counter="input_popup[0].input_class_character_counter" :permission="input_popup[0].permission" :icon_permission="input_popup[0].icon_permission" :character_counter_permission="input_popup[0].character_counter_permission" :helper_permission="input_popup[0].helper_permission" :test_field_is_numeric="input_popup[0].test_field_is_numeric" :length="input_popup[0].length" :is_input_textarea="input_popup[0].is_input_textarea" class="input-style-tlf" />
                        </div>
                    </div>
                    <div id="popup_email-container" class="popup_email-container">
                        <span>
                            <b>Email</b>
                        </span>
                        <div>
                            <InputTextOutline @_emit_method_order_input="_getUserEmail" :input_label="input_popup[1].input_label" :has_focus="input_popup[1].has_focus" :icon_class="input_popup[1].icon_class" :input_key_to_save_user_values="input_popup[1].input_key_to_save_user_values" :input_class_character_counter="input_popup[1].input_class_character_counter" :permission="input_popup[1].permission" :icon_permission="input_popup[1].icon_permission" :character_counter_permission="input_popup[1].character_counter_permission" :helper_permission="input_popup[1].helper_permission" :test_field_is_numeric="input_popup[1].test_field_is_numeric" :length="input_popup[1].length" :is_input_textarea="input_popup[1].is_input_textarea" class="input-style-email" />
                        </div>
                        <div class="btn-send-container">
                            <button class="btn-send" @click="_sendInfo()"> ENVIAR </button>
                        </div>
                    </div>
                </div>
                <div class="btn-close-container">
                    <button class="material-icons-outlined btn-close" @click="_closePopup()">close</button>
                </div>
            </div>
        </div>
        <div id="calc-form-container" class="calc-form-container">
            <div id="data-container" class="data-container">
                <div id="calculator-container" class="calculator-container">
                    <div id="initial-capital-container" class="initial-capital-container">
                        <InputTextOutline @_emit_method_order_input="_getValueFromInitialCapital" :input_label="input_compound_interest[0].input_label" :has_focus="input_compound_interest[0].has_focus" :icon_class="input_compound_interest[0].icon_class" :input_key_to_save_user_values="input_compound_interest[0].input_key_to_save_user_values" :input_class_character_counter="input_compound_interest[0].input_class_character_counter" :permission="input_compound_interest[0].permission" :icon_permission="input_compound_interest[0].icon_permission" :character_counter_permission="input_compound_interest[0].character_counter_permission" :helper_permission="input_compound_interest[0].helper_permission" :test_field_is_numeric="input_compound_interest[0].test_field_is_numeric" :length="input_compound_interest[0].length" :is_input_textarea="input_compound_interest[0].is_input_textarea" class="input-style" />
                    </div>
                    <div id="contribution-container" class="contribution-container">     
                        <div id="contribution-input" class="contribution-input">
                            <InputTextOutline @_emit_method_order_input="_getValueFromContribution" :input_label="input_compound_interest[1].input_label" :has_focus="input_compound_interest[1].has_focus" :icon_class="input_compound_interest[1].icon_class" :input_key_to_save_user_values="input_compound_interest[1].input_key_to_save_user_values" :input_class_character_counter="input_compound_interest[1].input_class_character_counter" :permission="input_compound_interest[1].permission" :icon_permission="input_compound_interest[1].icon_permission" :character_counter_permission="input_compound_interest[1].character_counter_permission" :helper_permission="input_compound_interest[1].helper_permission" :test_field_is_numeric="input_compound_interest[1].test_field_is_numeric" :length="input_compound_interest[1].length" :is_input_textarea="input_compound_interest[1].is_input_textarea" class="input-style" />
                        </div>
                        <div id="contribution-select" class="contribution-select">
                            <SelectOutline :ref="select_compound_interest_calculator[0].ref" :component_class="select_compound_interest_calculator[0].component_class" :label_name="select_compound_interest_calculator[0].label_name" :options="select_compound_interest_calculator[0].options" :helper_select_permission="select_compound_interest_calculator[0].helper_select_permission" @_emit_method_order_select="_getValueFromContributionSelect" class="select-style"/>
                        </div>
                    </div>
                    <div id="annual-interest-rate" class="annual-interest-rate">
                        <div id="annual-interest-rate-input" class="annual-interest-rate-input">
                            <InputTextOutline @_emit_method_order_input="_getValueFromAnnualInterestRate" :input_label="input_compound_interest[2].input_label" :has_focus="input_compound_interest[2].has_focus" :icon_class="input_compound_interest[2].icon_class" :input_key_to_save_user_values="input_compound_interest[2].input_key_to_save_user_values" :input_class_character_counter="input_compound_interest[2].input_class_character_counter" :permission="input_compound_interest[2].permission" :icon_permission="input_compound_interest[2].icon_permission" :character_counter_permission="input_compound_interest[2].character_counter_permission" :helper_permission="input_compound_interest[2].helper_permission" :test_field_is_numeric="input_compound_interest[2].test_field_is_numeric" :length="input_compound_interest[2].length" :is_input_textarea="input_compound_interest[2].is_input_textarea" class="input-style" />
                        </div>
                        <div id="interest-payment" class="interest-payment">
                            <SelectOutline :ref="select_compound_interest_calculator[2].ref" :component_class="select_compound_interest_calculator[2].component_class" :label_name="select_compound_interest_calculator[2].label_name" :options="select_compound_interest_calculator[2].options" :helper_select_permission="select_compound_interest_calculator[2].helper_select_permission"  @_emit_method_order_select="_getValueFromInterestPaymentSelect" class="payment-select-style"/>
                        </div>
                    </div>
                    <div id="period" class="period">
                        <div id="period-input" class="period-input">
                            <InputTextOutline @_emit_method_order_input="_getValueFromPeriod" :input_label="input_compound_interest[3].input_label" :has_focus="input_compound_interest[3].has_focus" :icon_class="input_compound_interest[3].icon_class" :input_key_to_save_user_values="input_compound_interest[3].input_key_to_save_user_values" :input_class_character_counter="input_compound_interest[3].input_class_character_counter" :permission="input_compound_interest[3].permission" :icon_permission="input_compound_interest[3].icon_permission" :character_counter_permission="input_compound_interest[3].character_counter_permission" :helper_permission="input_compound_interest[3].helper_permission" :test_field_is_numeric="input_compound_interest[3].test_field_is_numeric" :length="input_compound_interest[3].length" :is_input_textarea="input_compound_interest[3].is_input_textarea" class="input-style" />
                        </div>
                        <div id="period-select" class="period-select">
                            <SelectOutline :ref="select_compound_interest_calculator[1].ref" :component_class="select_compound_interest_calculator[1].component_class" :label_name="select_compound_interest_calculator[1].label_name" :options="select_compound_interest_calculator[1].options" :helper_select_permission="select_compound_interest_calculator[1].helper_select_permission" @_emit_method_order_select="_getValueFromPeriodSelect" class="select-style"/>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.res_container" id="res-values-container" class="res-values-container">
                <div id="bottom-line" class="bottom-line">
                    <p>
                        <br>
                        <br>
                        Balance Final: <br>
                        <span id="final-capital-value" class="final-capital-value">{{this.final_capital}}</span>
                    </p>
                </div>
                <div id="contribution-interest-container" class="contribution-interest-container">
                    <div id="total-contribution-container" class="total-contribution-container">
                        <p>
                            <br>
                            Aportación Total: <br>
                            <span id="final-capital-value" class="final-capital-value">{{this.total_deposits}}</span>
                        </p>
                    </div>
                    <div id="total-interest-container" class="total-interest-container">
                        <p>
                            <br>
                            Interés Total: <br>
                            <span id="final-capital-value" class="final-capital-value">{{this.total_interests}}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div id="btn-container" class="btn-container">
            <button @click="compound_interest()" id="btn-element" class="btn-element"> CALCULAR </button>
        </div>

        <div id="chart-container" class="chart-container">
            <div id="highChart-content" class="highChart-content"></div>
        </div>
    </div>
</template>

<script>

    /* HighChart */
    import Highcharts from "highcharts";

    /* Vue */
    import Vue from 'vue';

    /* Matertial Icons Outline */
    import SelectOutline from '@/components/helpers/SelectOutline.vue';
    import InputTextOutline from '@/components/helpers/InputTextOutline.vue';
    
    /* Getter */
    import { mapGetters } from "vuex";

    /* API Caller */
    import APICaller from "@/components/codeComponents/APICaller.js";

    /* JSON */
    import selectContribution from './configs/compound-interest-calculator/select-compound-interest.json';
    import inputCompoundInterest from './configs/compound-interest-calculator/input-compound-interest.json';
    import inputPopup from './configs/popup/input-popup.json';


    export default {
        mixins:[APICaller],

        name: 'CompoundInterestCalculator',

        metaInfo() {
            return {
                title: 'Calculadora de interés compuesto Eurekers',
                meta:[
                    { name: 'description', content: "La calculadora de interés compuesto permite calcular el importe total obtenido al pasar el tiempo establecido en tu inversión, ofrecida por Eurekers"}
                ]
            }
        },
        
        components: {
            SelectOutline,
            InputTextOutline,
        },

        data(){
            return{
                
                /* JSON */
                select_compound_interest_calculator: selectContribution,
                input_compound_interest: inputCompoundInterest,
                input_popup: inputPopup,

                /* Local Variables */
                input_initial_capital_value: '',
                input_contribution_value: '',
                selected_contribution_value: '',
                input_annual_interest_rate_value: '',
                input_period_value: '',
                selected_period_value: '',
                selected_interest_payment_value: '',
                input_final_capital_value: '',
                input_total_deposits_value: '',
                input_total_interest_value: '',
                input_total_interest_rate_value: '',
                values_accrued_interest: [],
                currency_value: 0,
                popup_counter: 0,
                user_tlf: '',
                user_email: '',
                show_popup: false,
                
                /* Res values */
                final_capital: '',
                total_deposits: '',
                total_interests: '',
                res_container: false,

                /* HighChart data */
                initial_investment: [],
                additional_deposits_data: [],
                accumulated_interest_data: [],
                x_categories: [],
            };
        },

        mounted(){

        },

        computed: {
            ...mapGetters(['is_mobile_device']),
        },

        created(){
            this._isIframe();
        },

        methods: {
            
            /* Initial Capital method */
            _getValueFromInitialCapital(value){
                this.input_initial_capital_value = value[0];
            },
            
            /* Contribution methods */
            _getValueFromContribution(value) {
                this.input_contribution_value = value[0];
            },
            _getValueFromContributionSelect(value){
                this.selected_contribution_value = value[0];           
            },

            /* Annual Interest Rate method */
            _getValueFromAnnualInterestRate(value){
                this.input_annual_interest_rate_value = value[0];
            },

            /* Period methods */
            _getValueFromPeriod(value){
                this.input_period_value = value[0];
            },
            _getValueFromPeriodSelect(value){
                this.selected_period_value = value[0];
            },
            
            /* Interest Payment method */
            _getValueFromInterestPaymentSelect(value){
                this.selected_interest_payment_value = value[0];
            },

            /* Final Capital method */
            _getValueFromFinalCapital(value){
                this.input_final_capital_value = value[0];
            },

            /* Total Deposits method */
            _getValueFromTotalDeposits(value){
                this.input_total_deposits_value = value[0];
            },

            /* Total Interest method */
            _getValueFromTotalInterest(value){
                this.input_total_interest_value = value[0];
            },

            /* Total Interest Rate method */
            _getValueFromTotalInterestRate(value){
                this.input_total_interest_rate_value = value[0];
            },

            /* User Tlf */
            _getUserTlf(value){
                this.user_tlf = value[0];
            },

            /* User Email */
            _getUserEmail(value){
                this.user_email = value[0];
            },

            _sendInfo(){
                
                this.result = true;
                this.result = this.result * this._emailValidator(this.user_email) * this._tlfValidator(this.user_tlf);
                
                if(this.result){

                    var cookieStorage = window.localStorage;
                    cookieStorage.setItem("email", this.user_email);
                    cookieStorage.setItem("phone_number", this.user_tlf);

                    let data = new URLSearchParams();
                    data.append('email', this.user_email);
                    data.append('tlf', this.user_tlf);

                    this._postInfo(data);
                }
            },

            _postInfo(data){
                let success = response => {
                    if(response.status == 200){
                        this._closePopup();
                    } else{
                        this.$vueOnToast.pop('error', 'Ha ocurrido un error. Por favor, inténtelo de nuevo.');
                    }
                };

                let url = "/calculadora/datos"

                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler('294', 'Error en la calculadora.');

                this._postAPICall(url, data, successHandler, failureHandler);

            },

            _tlfValidator: function(valor){
                if(/^\d{9}$/.test(valor)){
                    return true;
                }else{
                    this.$vueOnToast.pop('error', 'Numero de telefono incorrecto.');
                    return false;
                }
            },

            _emailValidator: function(valor) {
                if(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(valor)){
                return true;
                }else{
                    this.$vueOnToast.pop('error', 'Email incorrecto.');
                    return false;
                } 
            },

            _openPopUp: function(){
                this.show_popup = false;
                var cookieStorage = window.localStorage;
                var em = cookieStorage.getItem("email");
                var tel = cookieStorage.getItem("phone_number");
                if((this.popup_counter == 3 || this.popup_counter == 10) && (em == null || tel == null)){
                    this.show_popup = true;
                }
            },

            _closePopup(){
                this.show_popup = false;
            },

            compound_interest(){

                /* Rebuilding chart */
                this.initial_investment = [];
                this.additional_deposits_data = [];
                this.values_accrued_interest = [];
                this.accumulated_interest_data = [];
                this.x_categories = [];

                /* Useful data */
                var months = 12;
                
                /* Getting data */
                var Ci = this.input_initial_capital_value;
                var r_value = this.input_annual_interest_rate_value;
                var r = r_value / 100;
                var t = this.input_period_value;
                var t_value = this.selected_period_value;
                var Cr = this.input_contribution_value;
                var s = 0;
                var contributionPeriodicity = this.selected_contribution_value;
                var n_value = this.selected_interest_payment_value;
                var n = 0;

                /* Max Chart Value */
                
                
                /* Periodicity Casuistry */
                if(t_value == 'months'){
                    t = t / 12;
                } else if(t_value == 'trimesters'){
                    t = (t * 3) / 12;
                } else if(t_value == 'quarters'){
                    t = (t * 4) / 12;
                } else if(t_value == 'semesters'){
                    t = (t * 6) / 12;
                } else{
                    t = (t * 12) / 12;
                }

                /* Contribution Periodicity Casuistry */
                if(contributionPeriodicity == 'monthly-contribution'){
                    s = months;
                } else if(contributionPeriodicity == 'trimesterly-contribution'){
                    s = months / 3;
                } else if(contributionPeriodicity == 'quarterly-contribution'){
                    s = months / 4;
                } else if (contributionPeriodicity == 'semesterly-contribution'){
                    s = months / 6;
                } else {
                    s = 1;
                }

                /* Interest Payment Periodicity Casuistry */
                if(n_value == 'monthly'){
                    n = months;
                } else if(n_value == 'trimesterly'){
                    n = months / 3;
                } else if(n_value == 'quarterly'){
                    n = months / 4;
                } else if(n_value == 'semesterly'){
                    n = months / 6;
                } else {
                    n = 1;
                }

                if(Ci == "" || Cr == "" || r == "" || t == "" || contributionPeriodicity == "" || t_value == "" || n_value == ""){

                    this.$vueOnToast.pop('error', 'Por favor, rellene todos los campos.');

                } else if(Ci < 0 || r < 0 || t < 0 || Cr < 0){

                    this.$vueOnToast.pop('error', 'Por favor, introduzca valores positivos.');

                } else{

                    /* FINAL CAPITAL */
                    var Cf_first_component_powed = Math.pow((1+(r/n)), n*t);
                    var Cf_first_component = Ci * Cf_first_component_powed;
                    var Cf_second_component_powed_numerator = Math.pow((1+(r/n)), n*t);
                    var Cf_second_component_powed_denominator = Math.pow((1+(r/n)), n/s);
                    var Cf_second_component_minus_one_numerator = Cf_second_component_powed_numerator - 1;
                    var Cf_second_component_minus_one_denominator = Cf_second_component_powed_denominator - 1; 
                    var Cf_division = Cf_second_component_minus_one_numerator / Cf_second_component_minus_one_denominator;
                    var Cf_second_component = Cr * Cf_division;
    
                    var Cf = Math.round(Cf_first_component + Cf_second_component);
    
                    this.final_capital = Cf + ' €';
                    
                    /* TOTAL DEPOSITS */
                    this.total_deposits = parseInt(Ci) + parseInt(Cr) * parseInt(t) * parseInt(s) + ' €';
    
                    /* TOTAL INTERESTS */
                    this.total_interests = parseInt(Cf) - (parseInt(Ci) + parseInt(Cr) * parseInt(t) * parseInt(s)) + ' €';

                    /* TOTAL ADDITIONAL DEPOSITS */
                    if(contributionPeriodicity == 'monthly-contribution'){
                        this.input_total_additional_value = 0;
                        for(var i = 0; i < t; i++){
                            this.input_total_additional_value += Cr*12;
                        }
                        this.input_total_additional_value = this.input_total_additional_value + this.selected_currency_label_name;
                    } else if(contributionPeriodicity == 'trimesterly-contribution'){
                        this.input_total_additional_value = 0;
                        for(var j = 0; j < t; j++){
                            this.input_total_additional_value += Cr*4;
                        }
                        this.input_total_additional_value = this.input_total_additional_value + this.selected_currency_label_name;
                    } else if(contributionPeriodicity == 'quarterly-contribution'){
                        this.input_total_additional_value = 0;
                        for(var k = 0; k < t; k++){
                            this.input_total_additional_value += Cr*3;
                        }
                        this.input_total_additional_value = this.input_total_additional_value + this.selected_currency_label_name;
                    } else if (contributionPeriodicity == 'semesterly-contribution'){
                        this.input_total_additional_value = 0;
                        for(var u = 0; u < t; u++){
                            this.input_total_additional_value += Cr*6;
                        }
                        this.input_total_additional_value = this.input_total_additional_value + this.selected_currency_label_name;
                    } else {
                        this.input_total_additional_value = 0;
                        for(var p = 0; p < t; p++){
                            this.input_total_additional_value += parseInt(Cr);
                        }
                        this.input_total_additional_value = this.input_total_additional_value + this.selected_currency_label_name;
                    }
    
                    /* TOTAL INTERESTS RATE */
                    this.total_interests_rate = ((parseInt(Cf) - (parseInt(Ci) + parseInt(Cr) * parseInt(t) * parseInt(s))) / (parseInt(Ci) + parseInt(Cr) * parseInt(t) * parseInt(s))) * 100;
                    this.total_interests_rate = Math.round(this.total_interests_rate * 100) / 100 + '%';

                    /* Make res visibles */
                    this.res_container = true;
                    
                    /* Chart Builder */
                    this._chartBuilder();

                    this.popup_counter++;
                    this._openPopUp();

                    this._addDataToHighchart();
                }

                if(this.is_mobile_device == true){
                    setTimeout(() => {
                        window.scrollTo(0, document.body.scrollHeight);
                    }, 1000);
                }

            },

            _chartBuilder(){

                var Ci = this.input_initial_capital_value;
                var t = this.input_period_value;
                var t_value = this.selected_period_value;
                var Cr = this.input_contribution_value;
                var contributionPeriodicity = this.selected_contribution_value;
                var r = this.input_annual_interest_rate_value;
                var r_value = this.selected_interest_payment_value;

                this._setAccruedInterest(Ci, Cr, contributionPeriodicity, r, r_value, t);

                this._setInitialInvestmentData(t);

                if(t_value == 'months'){
                    this._setMonthlyCategory(t);
                } else if(t_value == 'trimesters'){
                    this._setTrimesterlyCategory(t);
                } else if(t_value == 'quarters'){
                    this._setQuarterlyCategory(t);
                } else if(t_value == 'semesters'){
                    this._setSemesterlyCategory(t);
                } else{
                    this._setAnnualCategory(t);
                }

                if(contributionPeriodicity == 'monthly-contribution'){
                    this._setMonthlyContributionData(Cr, t);
                } else if(contributionPeriodicity == 'trimesterly-contribution'){
                    this._setTrimesterlyContributionData(Cr, t);
                } else if(contributionPeriodicity == 'quarterly-contribution'){
                    this._setQuarterlyContributionData(Cr, t);
                } else if (contributionPeriodicity == 'semesterly-contribution'){
                    this._setSemesterlyContributionData(Cr, t);
                } else {
                    this._setAnnualContributionData(Cr, t);
                }
            },

            _setMonthlyCategory(t){
                for(var i = 1; i <= t; i++){
                    this.x_categories.push('Mes ' + i);
                }
            },
            _setTrimesterlyCategory(t){
                for(var i = 1; i <= t; i++){
                    this.x_categories.push('Trimestre ' + i);
                }
            },
            _setQuarterlyCategory(t){
                for(var i = 1; i <= t; i++){
                    this.x_categories.push('Cuatrimestre ' + i);
                }
            },
            _setSemesterlyCategory(t){
                for(var i = 1; i <= t; i++){
                    this.x_categories.push('Semestre ' + i);
                }
            },
            _setAnnualCategory(t){
                for(var i = 1; i <= t; i++){
                    this.x_categories.push('Año ' + i);
                }
            },

            
            _setMonthlyContributionData(Cr, t){
                this.additional_deposits_data = [];
                var additional_deposits_values = 0;
                for(var i = 1; i <= t; i++){
                    additional_deposits_values+= Cr*12;
                    this.additional_deposits_data.push(additional_deposits_values);
                }
            },
            _setTrimesterlyContributionData(Cr, t){
                this.additional_deposits_data = [];
                var additional_deposits_values = 0;
                for(var i = 1; i <= t; i++){
                    additional_deposits_values+= Cr*4;
                    this.additional_deposits_data.push(additional_deposits_values);
                }
            },
            _setQuarterlyContributionData(Cr, t){
                this.additional_deposits_data = [];
                var additional_deposits_values = 0;
                for(var i = 1; i <= t; i++){
                    additional_deposits_values+= Cr*3;
                    this.additional_deposits_data.push(additional_deposits_values);
                }
            },
            _setSemesterlyContributionData(Cr, t){
                this.additional_deposits_data = [];
                var additional_deposits_values = 0;
                for(var i = 1; i <= t; i++){
                    additional_deposits_values+= Cr*6;
                    this.additional_deposits_data.push(additional_deposits_values);
                }
            },
            _setAnnualContributionData(Cr, t){
                this.additional_deposits_data = [];
                var additional_deposits_values = 0;
                for(var i = 1; i<= t; i++){
                    additional_deposits_values += parseInt(Cr);
                    this.additional_deposits_data.push(additional_deposits_values);
                }
            },
            
            _setInitialInvestmentData(t){
                this.initial_investment = [];
                var Ci = this.input_initial_capital_value;
                for(var i = 1; i <= t; i++){
                    this.initial_investment.push(parseInt(Ci));
                }
            },

            _setAccruedInterest(Ci, Cr, contributionPeriodicity, r, r_value, t){
                var initial_balance = parseInt(Ci);
                var contribution = 0;
                var contribution_periodicity = contributionPeriodicity;

                if(contribution_periodicity == 'monthly-contribution'){
                    contribution = Cr * 12;
                } else if(contribution_periodicity == 'trimesterly-contribution'){
                    contribution = Cr * 4;
                } else if(contribution_periodicity == 'quarterly-contribution'){
                    contribution = Cr * 3;
                } else if (contribution_periodicity == 'semesterly-contribution'){
                    contribution = Cr * 2;
                } else{
                    contribution = Cr * 1;
                }

                var annual_interest = parseInt(r) / 100;

                var period = parseInt(t);

                var accrued_interest = (initial_balance + contribution) * annual_interest;
                this.values_accrued_interest.push(accrued_interest);

                /* Calculation of accrued interest */
                for(var i = 1; i < period; i++){
                    initial_balance = initial_balance + contribution + accrued_interest;
                    accrued_interest = (initial_balance + contribution)* annual_interest;
                    this.values_accrued_interest.push(accrued_interest);
                }

                var previous = 0;

                /* Calculation of accumulated accrued interest */
                for(var j = 0; j < this.values_accrued_interest.length; j++){
                    var plus = previous + this.values_accrued_interest[j];
                    previous = plus;
                    this.accumulated_interest_data.push(Math.round((plus + Number.EPSILON) * 100) / 100);
                }
            },

            _isIframe(){
                var URLactual = window.location;
                if(URLactual.search == "?=iframe"){
                    let sidebar = document.getElementById("sidebar-nav-container");
                    sidebar.style.display = "none";
                }
            },

            _addDataToHighchart: function(){
                
                let self = this;

                Vue.nextTick(function() {
                    Highcharts.chart('highChart-content', {
                        chart: {
                            type: 'column'
                        },
                        title: {
                            text: '<b>Gráfica de Interés Compuesto</b>'
                        },
                        xAxis: {
                            title: '<b>Tiempo</b>',
                            categories: self.x_categories
                        },
                        yAxis: {
                            //min: 0,
                            //max: 500000,
                            title: {
                                text: '<b>Aportación</b>'
                            }
                        },
                        tooltip: {
                            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}€</b><br/>',
                            shared: true
                        },
                        plotOptions: {
                            column: {
                                 stacking: 'normal'
                            }
                        },
                        series: [{
                            name: 'Interés anual',
                            data: self.accumulated_interest_data,
                            color: '#00ADD9'
                        },{
                            name: 'Aportaciones periódicas',
                            data: self.additional_deposits_data,
                            color: '#0F0F1A'
                        },{
                            name: 'Inversión inicial',
                            data: self.initial_investment,
                            color: '#000010'
                        }]
                    });
                })
            },
        },
    }
</script>

<style scoped>

    .main-container{
        margin-left: 30px;
        margin-top: 70px;
    }

    #title-container * {
        color: var(--color-text-primary);
    }

    h1 {
        margin: 0;
    }

    #chart-container {
        margin: 0;
        padding: 50px;
        background-color: var(--color-background);
    }
    
    .contribution-container{
        display: flex;
        margin-top: 20px;
    }

    .input-style{
        width: 250px;
        border-color: grey;
    }

    .select-style{
        width: 250px;
    }

    .contribution-select,
    .interest-payment,
    .period-select{
        margin-left: 20px;
    }

    .payment-select-style{
        width: 250px;
    }

    .annual-interest-rate{
        display: flex;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .period{
        display: flex;
    }

    .frecuency-1 span,
    .frecuency-2 span,
    .frecuency-3 span{
        font-size: 14px;
        margin-left: 10px;
    }

    .initial-investment span,
    .periodic-contribution span,
    .periodic-rate span,
    .period-text span{
        font-size: 14px;
        margin-left: 10px;
    }

    .contribution-interest-container{
        display: inline-flex;
        margin-top: 20px;
        width: 800px;
    }

    .total-contribution-container,
    .total-interest-container{
        background-color: var(--color-surface);
        width: 350px;
        height: 150px;
        text-align: center
    }

    .total-contribution-container *,
    .total-interest-container *,
    .bottom-line * {
        color: var(--color-text-secondary);
    }

    .total-interest-container{
        margin-left: auto;
    }

    .calc-form-container{
        display: inline-flex;
        margin-top: 50px;
    }

    .res-values-container{
        margin-left: 120px;
    }

    .bottom-line{
        background-color: var(--color-surface);
        width: 800px;
        height: 150px;
        text-align: center;
    }

    .final-capital-value{
        font-weight: 900;
        font-size: 35px;
    }

    .btn-element{
        width: 180px;
        height: 40px;
        background-color: #0faed7;
        color: white;
        font-weight: bold;
        border: none;
        border-radius: 18px;
        cursor: pointer;
    }

    .btn-element:hover{
        background-color: #42B5E3;
    }
    .btn-element:active{
        margin-top: 1px;
        background-color: #42B5F4;
    }
    .btn-container{
        margin-top: 50px;
    }
    .highChart-content{
        margin-top: 50px;
    }

    .popup-modal{
        position: absolute;
        top: 30%;
        left: 30%;
        z-index: 1002;
        background-color: white;
        border-width: 1px;
        border-style: solid;
        border-color: black;
        padding: 10px;
        width: 700px;
        height: 450px;
        animation: fadeIn 1s;
        display: inline-flex;
    }

    .popup-style-container{
        position: fixed;
        height: 100vh;
        width: 100vw;
        z-index: 1001;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.5);
    }

    @keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
    }

    .btn-close{
        border: none;
        cursor: pointer;
        background-color: transparent;
    }

    .input-style-tlf{
        width: 200px;
        margin-top: 10px;
    }

    .input-style-email{
        width: 200px;
        margin-top: 10px;
    }

    .popup_tlf-container{
        width: 300px;
    }

    .popup_email-container{
        width: 300px;
        margin-top: 10px;
    }

    .popup-form-container{
        width: 400px;
        margin-top: 50px;
        margin-left: 100px;
    }

    .btn-close-container{
        margin-left: auto;
    }

    .btn-send{
        width: 180px;
        height: 40px;
        background-color: #0faed7;
        color: white;
        font-weight: bold;
        border: none;
        border-radius: 20px;
        cursor: pointer;
        margin-top: 20px;
    }
    .btn-send:hover{
        background-color: #10B9E4;
    }
    .btn-send:active{
        margin-top: 21px;
    }

    /*html{
        overflow: hidden;
    }*/

    @media screen and (min-width: 631px){
        .main-container > * {
            margin: 50px 5%;
        }

        .main-container {
            margin: 0;
        }
    }

    @media screen and (max-width: 630px){
        .main-container{
            margin: 0 auto !important;
            width: 90%;
        }

        #title-container {
            margin-top: 50px;
        }
        
        .contribution-container{
            margin-top: 40px;
            display: block;
        }

        .annual-interest-rate{
            margin-top: 40px;
            display: block;
            margin-bottom: 40px;
        }

        .period{
            display: block;
        }

        .contribution-select,
        .interest-payment,
        .period-select{
            margin-top: 5px;
            margin-left: 0px;
        }

        .highChart-content{
            margin-left: -10px;
        }

        .btn-element{
            margin-left: 30px;
        }

        .popup-modal{
            top: 25%;
            left: 7%;
            width: 300px;
            height: 550px;
        }
        .popup-form-container{
            margin-left: 0px;
        }
        .text-container{
            width: 200px;
        }
        .btn-close-container{
            margin-left: -180px;
        }
    }

    /*@media screen and (min-width: 501px and max-width: 600px){
        .popup-modal{
            top: 25%;
            left: 7%;
            width: 300px;
            height: 550px;
        }
        .popup-form-container{
            margin-left: 0px;
        }
        .text-container{
            width: 200px;
        }
        .btn-close-container{
            margin-left: -180px;
        }
    }*/
</style>